@import 'styles/foundation';

.container {
  display: flex;
  flex-direction: column;
  gap: $px_40;

  .tabContent {
    padding-top: $px_24;
  }

  @include media-breakpoint-down(lg) {
    gap: $px_24;
  }

  .header {
    max-width: 830px;
    text-align: center;
    font-size: 88px;
    font-weight: $mediumWeight;
    line-height: 85px; /* 96.591% */
    letter-spacing: -3.5px;
    margin: 0 auto;

    @include media-breakpoint-down(lg) {
      font-size: 50px;
      padding: $px_0 $px_80;
      line-height: 100%; /* 32px */
      letter-spacing: -2px;
      margin-bottom: $px_16;
    }

    @include media-breakpoint-down(sm) {
      font-size: 44px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 32px;
    }
  }
}
