@import 'styles/foundation';

.container {
  width: min(100vw, 2500px);
  padding: $px_0;
  height: 540px;
  display: flex;

  @include media-breakpoint-between(lg, xl) {
    height: 620px;
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  &.small {
    width: 100%;
    height: 350px;

    .content {
      p {
        max-width: 100%;
      }

      .header,
      .zh.header {
        font-size: $px_40;
        line-height: 1.2em;
        letter-spacing: normal;
      }

      .subheader {
        font-size: $px_16;
        line-height: 1.2em;
        letter-spacing: normal;
      }
    }
  }

  .content {
    width: 50%;
    padding: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > p {
      max-width: 624px;
    }

    .header {
      color: var(--secondary-yellow-1, #fffbf0);

      /* H1 */
      font-size: $px_80;
      font-weight: $mediumWeight;
      line-height: 85px; /* 96.591% */
      letter-spacing: -3.5px;
      margin-bottom: $px_32;

      &.zh {
        font-size: $px_82;
      }
    }

    .subheader {
      color: var(--secondary-yellow-1, #fffbf0);
      font-size: $px_20;
      font-weight: $mediumWeight;
      line-height: 98%; /* 21.56px */
      letter-spacing: -0.8px;
      margin-bottom: $px_48;
    }

    .CTA {
      display: flex;
      gap: $px_24;

      .button {
        border-radius: 1000px;
        padding: $px_16 $px_24;
        background-color: $systemColourBlack;

        span {
          color: var(--secondary-yellow-1, #fffbf0);
          text-align: center;
          font-size: $px_16;
          font-style: normal;
          font-weight: $mediumWeight;
          line-height: 123%; /* 19.68px */
          letter-spacing: -0.5px;
        }
      }

      .white {
        border: $px_2 solid $systemColourBlack;
        background-color: $secondaryYellow1;

        span {
          color: $systemColourBlack;
        }
      }

      .link {
        display: flex;
        align-items: center;
        color: var(--secondary-yellow-1, #fffbf0);
        text-align: center;
        font-size: $px_16;
        font-weight: $mediumWeight;
        line-height: 123%; /* 19.68px */
        letter-spacing: -0.5px;
        text-underline-offset: $px_4;
      }
    }

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .bgImage {
    width: 100%;
    height: 540px;
    object-fit: cover;
    object-position: 50% 30%;
    @include media-breakpoint-between(lg, xl) {
      height: 620px;
    }

    @include media-breakpoint-down(lg) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .imageContainer {
    width: 50%;
    object-fit: cover;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      width: 100%;
      height: 100%;
      position: relative;
      background-color: #cfcfcf;
      background-blend-mode: multiply;
    }

    .imageText {
      visibility: hidden;

      @include media-breakpoint-down(lg) {
        position: absolute;
        bottom: 0;
        padding: $px_16;
        padding-bottom: $px_32;
        visibility: visible;
        display: flex;
        flex-direction: column;
        gap: $px_16;
        max-width: 549px;

        .header {
          color: var(--secondary-yellow-1, #fffbf0);
          font-size: $px_48;
          font-style: normal;
          font-weight: $mediumWeight;
          line-height: 98%; /* 47.04px */
          letter-spacing: -2px;
        }

        .subheader {
          color: var(--secondary-yellow-1, #fffbf0);
          font-size: $px_20;
          font-weight: $mediumWeight;
          line-height: 98%; /* 21.56px */
          letter-spacing: -0.8px;
        }

        .CTA {
          display: flex;
          gap: $px_24;

          .button {
            border-radius: 1000px;
            padding: $px_4 $px_16;

            span {
              text-align: center;
              font-size: $px_16;
              font-style: normal;
              font-weight: $mediumWeight;
              line-height: 123%; /* 19.68px */
              letter-spacing: -0.5px;
            }
          }

          .white {
            border: $px_2 solid $systemColourBlack;
            background-color: $secondaryYellow1;

            span {
              color: $systemColourBlack;
            }
          }

          .link {
            display: flex;
            align-items: center;
            color: var(--secondary-yellow-1, #fffbf0);
            text-align: center;
            font-size: $px_16;
            font-weight: $mediumWeight;
            line-height: 123%; /* 19.68px */
            letter-spacing: -0.5px;
            text-underline-offset: $px_4;
          }
        }
      }
    }
  }
}
