@import 'styles/foundation';

.container {
  max-width: min($componentWidth, calc(100vw - 160px));
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include media-breakpoint-down(xl) {
    display: none;
  }

  > div {
    flex: 0.33;
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;
    gap: $px_60;
  }

  .right {
    justify-content: end;
  }

  .middle {
    max-width: 330px;
    display: flex;
    justify-content: center;

    .carouselContainer {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      overflow-y: hidden;
      scroll-snap-type: x mandatory;
      scrollbar-width: none !important;

      &::-webkit-scrollbar {
        display: none;
      }

      > div {
        min-width: 330px;
        scroll-snap-align: start;
        margin-right: $px_20;
      }
    }
  }
}

.smallContainer {
  overflow-x: scroll;
  display: none;
  @include media-breakpoint-down(xl) {
    display: flex;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $px_48;
    align-items: center;
    @include media-breakpoint-down(lg) {
      gap: $px_16;
    }
  }
}
