@import 'styles/foundation';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  max-width: 312px;

  @include media-breakpoint-down(lg) {
    width: 100vw;
    align-items: center;
    scroll-snap-align: center;
  }

  // Transform the image color to #CC9C5E
  .imageContainer > img {
    filter: brightness(0) invert(63%) sepia(18%) saturate(999%) hue-rotate(354deg) brightness(99%) contrast(90%);
  }
}

.reportHighLight {
  margin-top: $px_20;
  text-align: center;
  font-size: $semiLargeFontSize;
  font-weight: $regularWeight;
  line-height: $regularLineHeight;
  letter-spacing: 0.37px;

  @include media-breakpoint-down(lg) {
    max-width: 312px;
    margin-top: $px_6;
  }
}

.reportLink {
  color: inherit;
  font-size: $smallFontSize;
  margin-top: $px_20;
}
