@import 'styles/foundation';

.container {
  gap: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @include media-breakpoint-down(lg) {
    gap: $px_48;
  }

  .cta {
    max-width: 864px;
    display: flex;
    flex-direction: column;
    gap: $px_32;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(lg) {
      padding: $px_0 $px_20;
      gap: $px_16;
    }

    .ctaHeader {
      color: var(--system-colour-black, #202020);
      text-align: center;
      font-size: 88px;
      font-weight: $mediumWeight;
      line-height: 85px; /* 96.591% */
      letter-spacing: -3.5px;

      @include media-breakpoint-down(lg) {
        font-size: $px_32;
        line-height: 100%; /* 32px */
        letter-spacing: -2px;
      }
    }

    .ctaSubheader {
      color: var(--system-colour-black, #202020);
      text-align: center;
      font-size: $px_20;
      font-weight: $mediumWeight;
      line-height: 98%; /* 21.56px */
      letter-spacing: -0.8px;

      @include media-breakpoint-down(lg) {
        font-size: 22px;
        line-height: 98%; /* 21.56px */
        letter-spacing: -0.8px;
      }
    }

    a {
      text-decoration: none;
    }

    .ctaButton {
      border-radius: 1000px;
      padding: $px_16 $px_24;
      background-color: $systemColourBlack;

      .span {
        color: var(--secondary-yellow-1, #fffbf0);
        text-align: center;
        font-size: $px_16;
        font-weight: $mediumWeight;
        line-height: 123%; /* 19.68px */
        letter-spacing: -0.5px;
      }
    }
  }

  .containedWidth {
    padding: $px_80 $px_0;
  }

  .CTASlide {
    height: 425px;
    background-image: url('/images/lp_banner.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $grey100;
    background-blend-mode: multiply;

    @include media-breakpoint-down(lg) {
      height: 366px;
    }

    .CTAText {
      text-align: center;
      color: $white;
    }

    .CTAHeader {
      color: $whiteText;
      text-align: center;
      text-shadow: 0 4px 4px rgb(0 0 0 / 14%);
      font-family: $fontFamilyHero;
      font-size: 40px;
      font-weight: 700;
      font-style: italic;
      line-height: 48px;
      letter-spacing: 0.37px;
      max-width: 866px;
      margin: 0 auto;
      margin-bottom: 22px;

      @include media-breakpoint-down(lg) {
        max-width: 572px;
        margin-bottom: 20px;
        font-size: 26px;
        font-weight: 700;
        line-height: 120%; /* 31.2px */
        letter-spacing: 0.37px;
      }
    }

    .CTASubtext {
      color: $whiteText;
      text-align: center;
      text-shadow: 0 4px 4px rgb(0 0 0 / 8%);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.38px;
      max-width: 592px;
      margin: 0 auto;
      margin-bottom: 16px;

      @include media-breakpoint-down(lg) {
        padding: 0 32px;
        font-size: 15px;
        line-height: 18px; /* 120% */
        letter-spacing: 0.38px;
        margin-bottom: 30px;
      }
    }

    .CTAButtons {
      > * {
        border-radius: $largeBorderRadius;
        border: 0;
        width: 208px;

        span {
          margin: auto;
        }
        @include media-breakpoint-down(lg) {
          width: 189px;
          display: block;
        }
      }

      display: flex;
      justify-content: center;
      flex-direction: row;
      margin-right: $px_10;
      gap: 40px;
      padding: 12px 36px;

      @include media-breakpoint-down(lg) {
        gap: 12px;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
      }
    }
  }

  .content {
    max-width: $containerWidth;
    margin: 0 auto;
  }

  .hotOffTheShelfContainer {
    max-width: $containerWidth;
    margin: 0 auto;
    padding: $px_48 0;
    padding-bottom: 0;

    @include media-breakpoint-down(lg) {
      padding: $px_32 24px;
      padding-bottom: 0;
    }
  }

  .mainContent {
    .madeInContent {
      > * {
        width: 50%;

        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }

      margin: 0 auto;
      padding: $px_48 93px;
      padding-bottom: 0;
      gap: 26px;
      max-width: $containerWidth;
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        padding: $px_32 24px;
        padding-bottom: 0;
      }
    }

    .bestSellersContent {
      overflow-x: hidden;
      max-width: $containerWidth;
      margin: 0 auto;
      padding: $px_48 80px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }

      @include media-breakpoint-down(lg) {
        padding: $px_32 24px;
        margin-bottom: 0;
      }
    }
  }

  .signUpContainer {
    margin: 0 84px;
    display: flex;
    gap: $px_24;

    li {
      position: relative;
      padding-left: $px_24;
      font-weight: $regularWeight;
      font-size: $almostLargeFontSize;
      line-height: 40px;
      letter-spacing: -0.24px;
    }

    .signUpContent li::after {
      content: '';
      background-color: transparent;
      position: absolute;
      left: 0;
      top: 13px;
      width: 6px;
      border-bottom: 1px solid $white;
      height: 11px;
      border-right: 1px solid $white;
      transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }

  .info {
    background: rgb(219 213 193 / 14%);
  }
}

.mediaColumnsContainer {
  margin-top: $px_48;
  margin-bottom: $px_80;
}

@include media-breakpoint-down(lg) {
  .container {
    .signUpContainer {
      margin: 1em;
      display: flex;
      flex-direction: column;
      gap: $px_12;
    }
  }
}
