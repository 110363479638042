@import 'styles/foundation';

.container {
  background-color: $secondaryOrange;
  color: $secondaryYellow3;
  overflow-x: hidden;

  .content {
    max-width: $containerWidth;
    padding: $px_48 $px_80;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    gap: $px_24;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .contentSlider {
    display: none;
    padding: 20px 0;
    @include media-breakpoint-down(lg) {
      display: block;
    }

    .sliderContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .imageContainer {
    display: flex;
    justify-content: center;
  }
}
