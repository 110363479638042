@import 'styles/foundation';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $systemColourBlack;
  text-align: center;
  font-weight: $mediumWeight;
  gap: $px_16;
  max-width: 100vw;

  @include media-breakpoint-down(xl) {
    padding: $px_0 $px_80;
  }

  @include media-breakpoint-down(lg) {
    padding: $px_0 $px_20;
  }

  .point {
    padding: $px_8;
    width: $px_48 !important;
    height: $px_48 !important;
    color: var(--secondary-yellow-1, #fffbf0);
    font-size: $px_20;
  }

  .header {
    font-size: $px_48;
    letter-spacing: -2px;
    line-height: 98%; /* 47.04px */
  }

  .subheader {
    font-size: $px_20;
    letter-spacing: -0.8px;
    line-height: 98%; /* 47.04px */
  }

  .mainTile {
    width: 50%;
  }
}
